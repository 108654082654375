import { CountrySelect } from 'mp-common/components/form/inputs'
import { InputLabeledContainer } from 'mp-common/components/form/containers'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useMemo } from 'react'
import setLanguage from 'next-translate/setLanguage'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { capitalize, find } from 'lodash'

const LanguageSwitcher = () => {
  const { t } = useTranslation()
  const { locale, defaultLocale } = useRouter()
  const localizeLanguage = useCallback((x) => {
    const displayNames = new Intl.DisplayNames([x], { type: 'language' })
    return capitalize(displayNames.of(x))
  }, [])

  const allowedLanguages = useMemo(() => [
    { id: 0, code: 'en', label: localizeLanguage('en'), value: 'US' },
    { id: 1, code: 'es', label: localizeLanguage('es'), value: 'ES' },
    { id: 2, code: 'pl', label: localizeLanguage('pl'), value: 'PL' }
  ], [])

  const value = useMemo(() => {
    if (locale) {
      return find(allowedLanguages, x => x.code === locale)?.value
    }
    return allowedLanguages[0].value
  }, [allowedLanguages, locale])

  const languageChanged = useCallback(async (option) => {
    const lang = allowedLanguages?.find((l) => l.value === option)?.code
    sessionStorage.clear()
    Cookies.set('NEXT_LOCALE', lang, { expires: 365, path: '/', secure: true, sameSite: 'strict' })
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('NEXT_LOCALE', lang)
    }
    await setLanguage(lang)
  }, [])

  function persistLocaleCookie() {
    if (locale !== defaultLocale) {
      Cookies.set('NEXT_LOCALE', locale, { expires: 365, path: '/', secure: true, sameSite: 'strict' })
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('NEXT_LOCALE', locale)
      }
    }
  }

  useEffect(persistLocaleCookie, [locale, defaultLocale])

  return (
    <InputLabeledContainer
      name="language"
      form={{}}
      label={t('userMenu:language')}
      renderInput={() => (
        <CountrySelect
          items={allowedLanguages}
          value={value}
          onSet={(v) => languageChanged(v)}
        />
      )}
    />
  )
}

export default LanguageSwitcher
