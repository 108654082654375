import { useMemo } from 'react'
import { GHref, GImage } from 'mp-common/components/form/elements'

export type SidebarSubItemProps = {
  id: string,
  title: string,
  link?: string
  icon?: string
  onClick?: () => void
}

export type SidebarUnitProps = {
  parentLink: string,
  title: string,
  subItems: SidebarSubItemProps[],
  currentLink: string
}

const SidebarUnit = (props : SidebarUnitProps) => {
  const {
    parentLink,
    title,
    subItems,
    currentLink
  } = props
  const isActive = useMemo(() => currentLink.includes(parentLink), [currentLink, parentLink])

  return (
    <ul>
      {title && (
        <li className={'tm-left-nav-title' + (isActive ? ' active' : '')}>
          <span className="nav-text">{title}</span>
        </li>
      )}
      {subItems?.length > 0 && subItems?.map((x: SidebarSubItemProps) => (
        <li key={x.id}>
          <GHref
            href={x.link || ''}
            title={x.title}
            onClick={x.onClick}
            className={currentLink.includes(x.link) ? 'active' : ''}
          >
            <GImage
              src={x.icon}
              alt="Load Board"
              className="nav-icon"
            />
            <span>{x.title}</span>
          </GHref>
        </li>
      ))}
    </ul>
  )
}

export default SidebarUnit
