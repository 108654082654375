import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import NotificationCard from 'mp-notifications/components/NotificationCard'
import GImage from '../../common/components/form/elements/GImage'
import GHref from '../../common/components/form/elements/GHref'

interface Props {
  setShowNotifications: (props: any) => void;
}

function Notifications(props: Props) {
  const { t } = useTranslation()
  const { setShowNotifications } = props
  const notifications = [
    {
      name: 'Tyler Durden',
      role: 'Logistic Manager',
      pic: 'chat-avatar-1.png',
      date: { ago: 25 },
      id: 1,
      flag: 'flag-germany.png',
      destId: 'DE28013',
      distance: 129000,
      distanceUnit: 'km',
      pickup: {
        location: 'Barcelona / Spain'
      },
      delivery: {
        location: 'Madrid / Spain'
      },
      message: 'He sent you a message and accepted your ES-9458684 load at the price you provided.',
      openingPrice: 2800,
      latestPrice: 2800,
      stopCount: 10
    }
  ]

  return (
    <>
      <GHref onClick={() => setShowNotifications(false)} className="tm-close-sidebar" />
      <header>
        <GImage src="icon-notifications.svg" alt="Notifications" />
        <span>{t('common:notifications')}</span>
      </header>
      <div className="tm-transactions-details-middle tm-custom-scroll" style={{ height: 'calc(100vh - 120px)', overflowX: 'hidden', position: 'relative' }}>
        <div className="tm-small-tabs-outer">
          <ul>
            <li>
              <GHref className="tab-negotiation-btn active" title="Negotiation">{t('common:negotiation')}</GHref>
            </li>
            <li>
              <GHref className="tab-message-btn" title="Message">{t('common:message')} <span className="tm-new-message-dot" /></GHref>
            </li>
            <li>
              <GHref className="tab-request-btn" title="Request">{t('common:request')}</GHref>
            </li>
          </ul>
        </div>
        {notifications?.map((n) => (
          <NotificationCard notification={n} key={n?.id} />
        ))}
      </div>
      <div className="tm-transactions-bottom-buttons">
        <GHref className="discard">{t('common:accept')}</GHref>
        <GHref className="confirm">{t('common:reject')}</GHref>
      </div>
    </>
  )
}

export default Notifications
