import useTranslation from 'next-translate/useTranslation'
import GImage from 'modules/common/components/form/elements/GImage'
import useSession from 'mp-auth/hooks/useSession'
import { GHref } from 'mp-common/components/form/elements'
import LanguageSwitcher from 'mp-header/components/LanguageSwitcher'
import dynamic from 'next/dynamic'
import React from 'react'

const EllipsisToolTip = dynamic(() => import('ellipsis-tooltip-react-chan'), { ssr: false })

const UserMenu = () => {
  const { t } = useTranslation('userMenu')
  const { user, userAvatar, logout, isAdmin } = useSession()

  return (
    <>
      <div className="tm-header-user-dd-top">
        <GImage src={userAvatar} width={48} />
        <div className="tm-header-user-dd-details">
          <div className="tm-user-name">
            <EllipsisToolTip>{user?.user}</EllipsisToolTip>
          </div>
          <div className="tm-cmp-name text-14">
            <EllipsisToolTip>{user?.company?.name}</EllipsisToolTip>
          </div>
        </div>
      </div>
      <div className="hr" />
      <ul>
        <li>
          <GHref href="/profile">
            <GImage src="icon-user.svg" alt={t('user_profile')} width={16} />
            {t('user_profile')}
          </GHref>
        </li>
        <li>
          <GHref href="/profile/settings">
            <GImage src="icon-settings.svg" alt={t('personal_settings')} width={16} />
            {t('personal_settings')}
          </GHref>
        </li>
      </ul>
      <div className="hr" />
      {isAdmin && user?.company && (
        <>
          <ul>
            <li>
              <GHref href="/company-profile">
                <GImage src="icon-company.svg" alt={t('company_profile')} width={16} />
                {t('company_profile')}
              </GHref>
            </li>
          </ul>
          <div className="hr" />
        </>
      )}
      <ul>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>
      <div className="hr" />
      <ul>
        <li>
          <GHref onClick={logout}>
            <GImage src="icon-logout.svg" alt={t('log_out')} width={16} />
            {t('log_out')}
          </GHref>
        </li>
      </ul>
    </>
  )
}

export default UserMenu
