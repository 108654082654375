import useInput from 'mp-common/components/form/hooks/useInput'
import React from 'react'

type Props = {
  className?: string
  labelClassName?: string
  label?: string
  errors?: Record<string, any>
  form?: Record<string, any>
  name?: string
  required?: boolean
  disabled?: boolean
  onChange?: (key: string, value: any) => void
  renderInput?: (any) => JSX.Element
}

const HorizontalInputLabelContainer = (props: Props) => {
  const {
    className = '',
    labelClassName = 'form-label mb-0',
    label = '',
    errors,
    form,
    name,
    required,
    disabled,
    onChange,
    renderInput
  } = props

  const { errorContent, errorText, value, onSet } = useInput({
    errors,
    form,
    name,
    onChange
  })

  return (
    <div className={className + ' mp-grid-10-col gap-16'}>
      <div className="column-3 align-self-center">
        <label className={labelClassName}>
          {' '}
          {label}
          {required && <span>*</span>}
          {errorContent}
        </label>

      </div>
      <div className="column-7">
        {renderInput && renderInput({ error: errorText, name, onSet, value, disabled, required })}
      </div>
    </div>
  )
}

export default React.memo<Props>(HorizontalInputLabelContainer)
