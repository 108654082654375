import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import useSession from 'mp-auth/hooks/useSession'
import GImage from '../../../../common/components/form/elements/GImage'
import SidebarUnit from './SidebarUnit'

const GeneralLayoutSidebar = () => {
  const router = useRouter()
  const { logout, isAdmin, user } = useSession()
  const { t } = useTranslation()
  const [isBarExpanded, setIsBarExpanded] = useState(false)
  const [isCacheApplied, setIsCacheApplied] = useState(false)
  const [isExpandedByCache, setIsExpandedByCache] = useState(false)
  const [isHoverDisabled, setIsHoverDisabled] = useState(false)

  const sidebarItems = useMemo(() => [
    {
      id: '1',
      title: t('sidebar:exchange_boards'),
      parentLink: 'fex',
      subItems: [
        {
          title: t('sidebar:freight'),
          link: '/fex/freight-board',
          id: 'freight_board',
          icon: 'icon-box-freight.svg'
        },
        {
          title: t('sidebar:mini_tender'),
          link: '/fex/tender-board',
          id: 'tender_board',
          icon: 'icon-tender-board.svg'
        },
        {
          title: t('sidebar:truck'),
          link: '/fex/truck-board',
          id: 'truck_board',
          icon: 'icon-truck.svg'
        }
      ]
    },
    {
      id: '2',
      title: t('sidebar:profile'),
      parentLink: 'profile',
      subItems: [{
        title: t('sidebar:user_profile'),
        link: '/profile',
        id: 'user_profile',
        icon: 'icon-user.svg'
      },
      ...(isAdmin && user?.company ? [{
        title: t('sidebar:company_profile'),
        link: '/company-profile',
        id: 'company_profile',
        icon: 'icon-company.svg'
      }] : [])]
    },
    {
      id: '3',
      title: '',
      parentLink: 'profile',
      subItems: [{
        title: t('sidebar:logout'),
        onClick: logout,
        id: 'logout',
        icon: 'icon-logout.svg'
      }]
    }
  ], [t, isAdmin, user?.company])

  const currentLink = useMemo(() => router?.asPath, [router?.asPath])

  const toggleContent = () => {
    document?.querySelector('.tm-header')?.classList.toggle('sidebar-opened')
    document?.querySelector('.tm-main')?.classList.toggle('sidebar-opened')
  }

  const toggleSideBar = (byCache = false) => {
    if (byCache) {
      setIsBarExpanded(true)
      setIsExpandedByCache(true)
      toggleContent()
      return
    }
    if (isBarExpanded) {
      setIsHoverDisabled(true)
      setTimeout(() => {
        setIsHoverDisabled(false)
      }, 100)
    }
    setIsExpandedByCache(false)
    setIsBarExpanded(x => !x)
    toggleContent()
  }

  useEffect(() => {
    const cachedSidebarPref = localStorage.getItem('cached_sidebar_pref')
    if (cachedSidebarPref === 'true' && !isCacheApplied) {
      setIsCacheApplied(true)
      toggleSideBar(true)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('cached_sidebar_pref', JSON.stringify(isBarExpanded))
  }, [toggleSideBar])

  return (
    <aside className={
      'tm-left-sidebar tm-admin-sidebar tm-ftt-sidebar'
      + (isBarExpanded ? ' expanded' : '')
      + (isExpandedByCache ? ' by-cache' : '')
      + (isHoverDisabled ? ' hover-disabled' : '')
    }>
      <div
        className={'tm-toggle-sidebar-btn' + (isBarExpanded ? ' active' : '')}
        onClick={() => toggleSideBar()}
      >
        <GImage src="icon-acc-down.svg" alt="" />
      </div>
      <div className="tm-left-sidebar-container">
        <div className="tm-left-sidebar-logo">
          <GImage src="brand-iso.svg" alt="Tennders" className="t-brand-icon" />
          <GImage src="brand-logo.svg" alt="Tennders" className="t-brand-text" />
        </div>
        {sidebarItems.map((x) => (
          <SidebarUnit
            key={x.id}
            parentLink={x.parentLink}
            title={x.title}
            subItems={x.subItems}
            currentLink={currentLink}
          />
        ))}
      </div>
    </aside>
  )
}

GeneralLayoutSidebar.displayName = 'GeneralLayoutSidebar'
export default GeneralLayoutSidebar
