import { useEffect, useMemo, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import Notifications from 'mp-notifications/views/Notifications'
import GImage from 'mp-common/components/form/elements/GImage'
import GHref from 'mp-common/components/form/elements/GHref'
import UserMenu from 'mp-header/components/UserMenu'
import useSession from 'mp-auth/hooks/useSession'
import useDetectOutsideClick from 'mp-common/hooks/useDetectOutsideClick'

function Header() {
  const { t } = useTranslation()
  const { fetchUser } = useSession()
  const { userAvatar } = useSession()
  const router = useRouter()
  const userAvatarRef = useRef(null)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)

  useDetectOutsideClick(userAvatarRef, () => setShowUserMenu(false))

  const headers = useMemo(() => [
    {
      path: 'fex/freight-board/add',
      icon: 'icon-add-new-load.svg',
      title: t('headers:add_freight')
    },
    {
      path: 'fex/truck-board/add',
      icon: 'icon-truck.svg',
      title: t('headers:add_truck')
    },
    {
      path: 'fex/tender-board/add',
      icon: 'icon-tender-board.svg',
      title: t('headers:add_tender')
    },
    {
      path: 'fex/freight-board',
      icon: 'icon-marketplace.svg',
      title: t('headers:tennders_fex')
    },
    {
      path: 'fex/truck-board',
      icon: 'icon-marketplace.svg',
      title: t('headers:tennders_fex')
    },
    {
      path: 'fex/tender-board',
      icon: 'icon-marketplace.svg',
      title: t('headers:tennders_fex')
    },
    // Company Profile
    {
      path: 'company-profile',
      icon: 'icon-company.svg',
      title: t('companyProfile:company_profile')
    },
    // Profile
    {
      path: 'profile',
      icon: 'icon-user.svg',
      title: t('profile:your_profile')
    },
    // Discover
    {
      path: 'discover',
      icon: 'icon-marketplace.svg',
      title: t('headers:fex')
    }
  ], [t])

  const header = useMemo(() => headers.find(h => router?.asPath?.indexOf(h.path) > 0), [router?.asPath, headers])

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <>
      {header && (
        <header className="tm-header">
          <div className="tm-header-top">
            <div className="tm-header-left">
              <GImage src={header.icon} alt={header?.title} />
              <h1>{header?.title}</h1>
            </div>
            <div className="tm-header-right">
              {/* TODO add back when backend development is completed */}
              {/* <GHref */}
              {/*  className="tm-header-notifications" */}
              {/*  title="Notifications" */}
              {/*  onClick={() => setShowNotifications(true)} */}
              {/* > */}
              {/*  <GImage src="icon-notifications.svg" alt="Notifications" /> */}
              {/*  <span className="notif-count">99+</span> */}
              {/* </GHref> */}
              <div ref={userAvatarRef}>
                <GHref
                  className="tm-header-user"
                  title="User Name"
                  onClick={() => setShowUserMenu(prevState => !prevState)}
                >
                  <GImage src={userAvatar} alt="User Name" width={32} height={32} />
                </GHref>
                <div className={'tm-header-user-dd' + (showUserMenu ? ' opened' : '')}>
                  {showUserMenu && (<UserMenu />)}
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
      <aside className={'tm-right-sidebar tm-notifications-sidebar' + (showNotifications ? ' opened' : '')}>
        {showNotifications && (
          <Notifications setShowNotifications={setShowNotifications} />
        )}
      </aside>
    </>
  )
}

export default Header
