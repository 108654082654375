import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import GImage from '../../common/components/form/elements/GImage'
import GHref from '../../common/components/form/elements/GHref'

type Props = {
    notification?: any
}

const NotificationCard = (props:Props) => {
  const { t } = useTranslation()
  const { notification } = props

  return (
    <div className="tm-small-tabs-content tm-transactions-content active notification-content">
      <div className="mb-16">
        <div className="d-flex justify-space align-center">
          <div className="left">
            <div className="d-flex align-center">
              <GImage src={notification?.pic} width={48} />
              <div className="ml-10">
                <div className="fw-600">{notification?.name}</div>
                <div className="text-12">{notification?.role}</div>
              </div>
            </div>
          </div>
          <div className="right text-right">
            <div className="mb-4 text-color-black-60">{notification?.date?.ago} min</div>
            <GHref className="text-color-black-100 fw-600">{t('common:go_to_detail')}</GHref>
          </div>
        </div>
      </div>
      <div className="mb-16">
        {notification?.message}
      </div>
      <div className="mb-16">
        <div className="d-flex justify-space align-center">
          <div className="left">
            {t('notification:opening_price')}: € {notification?.openingPrice}
          </div>
          <div className="text-right fw-700">
            {t('notification:latest_price')}: € {notification?.latestPrice}
          </div>
        </div>
      </div>
      <table className="tm-table-transactions tm-table-transactions-content">
        <tbody>
          <tr className="content">
            <td className="tm-dest-detail">
              <div className="tm-dest-grid">
                <GImage src={notification?.flag} width={40} height={40} />
                <div>
                  <div className="tm-dest-name">{notification?.pickup?.location}</div>
                </div>
              </div>
            </td>
            <td className="col-km-w-stops" colSpan={2}>
              <div className="col-km-w-stops-columns">
                <div className="column">
                  <div className="fw-600">{notification?.distance > 999 ? (notification?.distance / 1000) + 'k' : notification?.distance}</div>
                  <div className="mt-2">{notification?.distanceUnit}</div>
                </div>
                <div className="column">
                  <div className="fw-600">{notification?.stopCount}</div>
                  <div className="mt-2">{t('leads:stops')}</div>
                </div>
              </div>
            </td>
            <td className="tm-dest-detail border-right">
              <div className="tm-dest-grid">
                <GImage src={notification?.flag} alt="flag" width={40} height={40} />
                <div>
                  <div className="tm-dest-name" title="del-loc">{notification?.delivery?.location}</div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default NotificationCard
